body, html {
    padding: 0;
    margin: 0;
}

canvas, #zr {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

#ui {
    display: none;
    position: absolute;
    top: 0;
    left: 5px;
    padding: 10px 20px;
    z-index: 2;
}

#info {
    position: absolute;
    right: 10px;
    bottom: 10px;
}

#link {
    text-align: right;
}

#link a {
    display: inline-block;
    margin-right: 5px;
    color: #DB9BAC;
    font-size: 14px;
}

h1 {
    margin: 0;
    font-size: 22px;
    color: #D4597B;
}

h2 {
    margin: 0;
    font-size: 16px;
    color: #555;
    font-family: monospace;
}

h3 {
    margin: 3px 0;
    font-size: 25px;
    color: #D53D49;
}

.series-color {
    display: inline-block;
    width: 10px;
    height: 10px;
    border: 2px solid white;
    margin-right: 3px;
}

.series-color.active {
    width: 20px;
    height: 20px;
}
#lipstick-info {
    font-family: cursive;
}
#series-colors {
    cursor: pointer;
}
p {
    padding: 0;
    margin: 0;
    font-size: 15px;
}

header {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
}

h5 {
    margin-top: 10px;
}

.ecclips {
    text-align: right;
}

.ecclips .ant-row {
    width: 100%;
    display: block!important;

}

.ecclips .ant-row .ant-col {
    max-width: 100%!important;
}

.sendPickl {
    width: 80%;
    margin: auto;
}
.sendPickl ul li {
    list-style: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 5px 0;
}

.sendPickl ul li span {
    width: 20px;
    height: 20px;
    border: 2px solid #fff;
    margin-right: 10px;
    border-radius: 5px;
    border-image: linear-gradient(to right, #5c5a5a, #fff);
    border-image-slice: 1;
}

.liprTui {
    height: 40px;
    text-align: right;
}
.liprTui img {
    width: 30px;
    height: 30px;
    /* float: right; */
    cursor: pointer;
    vertical-align: middle;
}

@keyframes identifier {
    to {
        transform: rotate(-360deg);
    }
}

.changeImg {
    animation: identifier 3s linear forwards;
}

.priceLists ul {
    margin: 0;
    padding: 0;
}
.priceLists ul li {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 10px;
}
.priceLists ul li div {
    flex: 1;
}
.priceLists_one {
    flex: 0;
    width: 35px;
    height: 35px;
    border-radius: 5px;
}
.priceLists_two {
    font-weight: bold;
    text-align: center;
}
.priceLists_three {
    text-align: right;
}







/* @import url('https://fonts.googleapis.com/css?family=Kaushan+Script'); */

html,
body {
    height: 100%;
    width: 100%;
    margin: 0;
}

body {
    display: flex;
    justify-content: center;
    align-items: center;
}

.lipstick {
    position: relative;
    left: 50%;
    transform: translate(-50%,0);
    background-size: 500px;
    background-position-x: -150px;
    background-position-y: -30px;
    height: 408px;
    width: 107px;
    animation: lipstick 5s infinite alternate;
}

@keyframes lipstick {
    0% {
        transform: rotate(0);
    }

    10% {
        transform: rotate(0);
    }

    80% {
        transform: rotate(30deg);
    }

    100% {
        transform: rotate(30deg);
    }
}

.base {
    position: absolute;
    height: 154px;
    width: 80px;
    left: 16px;
    bottom: 11px;
    border-bottom-left-radius: 42px 20px;
    border-bottom-right-radius: 42px 20px;
    background: radial-gradient(ellipse 216px 154px at 50% 2%, #0c1717 25%, rgba(0, 0, 0, 0) 20%), linear-gradient(to right, #2d3538 50%, #0d0d0b 79%);
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.3);
}

.base:before {
    position: absolute;
    content: '';
    height: 58px;
    width: 80px;
    bottom: 116px;
    border-radius: 42px / 20px;
    background: radial-gradient(ellipse 209px 154px at 50% -6%, #d3e6ea 20%, #abc2c9 25%, rgba(0, 0, 0, 0) 26%), linear-gradient(to right, #cce0e4 50%, #415557 79%);
}

.base:after {
    position: absolute;
    content: '';
    height: 107px;
    width: 70px;
    left: 5px;
    bottom: 145px;
    border-radius: 42px / 20px;
    background: radial-gradient(ellipse 147px 49px at 50% 10%, #766768 12%, #5f686a 21%, rgba(0, 0, 0, 0) 23%), radial-gradient(ellipse 233px 165px at 50% 1%, #f7fdff 15%, rgba(0, 0, 0, 0) 19%), linear-gradient(to right, #cce0e4 64%, #415557 88%);
}

.tip {
    position: absolute;
    height: 130px;
    width: 53px;
    left: 30px;
    bottom: 242px;
    border-radius: 51px / 20px;
    border-top-left-radius: 59px 141px;
    border-top-right-radius: 59px 141px;
    background: radial-gradient(ellipse 105px 181px at 50% 2%, #ff4dbe 22%, rgba(0, 0, 0, 0) 24%), linear-gradient(to right, #FF1EAD 50%, #c80e84 79%);
    box-shadow: inset 0px -6px 11px #a00868;
    animation: tip 5s infinite alternate;
}

@keyframes tip {
    0% {
        height: 0px;
    }

    10% {
        height: 0px;
    }

    30% {
        height: 40px;
    }

    80% {
        height: 130px;
    }

    100% {
        height: 130px;
    }
}

.top {
    position: absolute;
    height: 154px;
    width: 80px;
    left: 16px;
    bottom: 152px;
    color: #222b2d;
    border-radius: 42px / 20px;
    background: radial-gradient(ellipse 216px 154px at 50% 2%, #222b2d 22%, #0c1717 25%, rgba(0, 0, 0, 0) 20%), linear-gradient(to right, #2d3538 50%, #0d0d0b 79%);
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.3);
    animation: top 5s infinite alternate;
}

.top:before {
    position: absolute;
    content: 'lipstick';
    color: #f1fcfe;
    transform: rotate(-90deg) skew(-10deg) rotateX(30deg);
    top: 73px;
    left: -23px;
    font-size: 30px;
    font-family: 'Kaushan Script', cursive;
}

@keyframes top {
    0% {
        bottom: 152px;
        transform: rotate(0);
        left: 16px;
    }

    10% {
        bottom: 152px;
        transform: rotate(0);
        left: 16px;
    }

    30% {
        bottom: 240px;
        transform: rotate(0);
        left: 16px;
    }

    80% {
        bottom: 400px;
        transform: rotate(30deg);
        left: 46px;
    }

    100% {
        bottom: 400px;
        transform: rotate(30deg);
        left: 46px;
    }
}